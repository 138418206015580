import React, { useState } from "react";
import { graphql } from "gatsby";
import { Seo } from "../../components/Seo";
import { SectionPost } from "../../view/blog/sectionPost";
import Layout from "../../templates/MainTemplate";
import { MainHome } from "../../view/blog/styledPost";
import {
  useListPost,
  //useTotalPosts,
  usePagePost,
} from "../../hooks/methods/blog";
import { useEffect } from "react";

import PostsConstants from "../../constants/posts.constants";

export default function Blog({ location }) {
  const [pageUpsell, setPageUpsell] = useState(1);
  const [skipPage, setSkipPage] = useState(0);

  useEffect(() => {
    setSkipPage(pageUpsell * 16 - 16);
  }, [pageUpsell]);

  let initalValue = 17;
  // const { data: value } = useTotalPosts();
  const { data: pagePostData } = usePagePost();

  const hashPage = location ? location?.hash : null;

  const hidden = hashPage === `#${PostsConstants.HIDDEN_POSTS_CATEGORY}`;

  const { data } = useListPost("date_DESC", initalValue, skipPage, hidden);

  return (
    <Layout>
      <MainHome>
        <h1>{pagePostData?.pagePost?.title}</h1>
        <h2>{pagePostData?.pagePost?.subtitle}</h2>
        <SectionPost
          details={data?.posts}
          hashPage={hashPage}
          location={location}
        />
      </MainHome>
    </Layout>
  );
}

export const Head = ({
  data: {
    cmsPagePost: {
      seo: {
        title: seoTitle,
        description: seoDescription,
        keywords,
        image: { url },
      },
    },
  },
}) => {
  const keywordsList = keywords.toString();
  return (
    <Seo
      title={seoTitle}
      description={seoDescription}
      keywords={keywordsList}
      url={url}
    />
  );
};

export const query = graphql`
  query PageQuery {
    cmsPagePost {
      seo {
        title
        description
        keywords
        image {
          url
        }
      }
    }
  }
`;
